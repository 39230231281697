@import url('https://fonts.googleapis.com/css2?family=Moon+Dance&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*, ::after, ::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  color: #333;
}

body{
  background-color: #fefdff;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}